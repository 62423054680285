import { createSelector } from 'reselect'
import { getSvfsWithRequiredProps } from '../utils/offline/generateSvfs'
import moment from 'moment'
import { getCurrentVisit } from '../selectors/visits'
import _ from 'lodash'
import constants from '../constants/constants'
import { getFieldGroupList, getFieldGroupListFormPrint, getFormPreviewFieldGroupList, getFormPreviewForm, getFormPrintForm, getForms, getUserStudyMetaData } from './studyMetaData'
import { getSelectedDate, getSelectedSvfId } from './session'
import { getAppType, getOnMobileStatus } from './storeAppStatus'
import { getModeFromSessionStorage } from '../utils/util'
import { getCrfDataPool, getUserCrfData } from './crfData'
import { disableForm } from '../utils/fieldutils/field'
import { fetchNextFieldByFieldRule } from '../utils/fieldutils/fieldRule'


const getState = (state) => state

const defaultSchema = {
  layout: 'scroll',
  styles: {
    field: {
      containerBorder: false,
      containerWidth: '50%',
    },
    options: {
      containerBorder: false,
      direction: 'vertical',
      labelPosition: 'right',
      containerWidth: '50%',
    },
  },
}


export const getSubjectSvfs = (state) => Object.values(state.subjectVisitForm.allSvfs)

export const getSvfsOfCurrentVisit = createSelector(
  [getCurrentVisit, getForms, getSelectedDate, getUserStudyMetaData, getAppType],
  (currentVisit, formList, scheduleDate, metadata, appType) => {
    if (!_.isEmpty(formList)) {
      let svfList = _.map(currentVisit.subjectVisitForms, (svf) => {
        const svfEventObj = getEventTimeSlot(currentVisit, svf, metadata)
        return {
          ...svf,
          form: _.filter(formList, (form) => form.id === svf?.form?.id)[0],
          eventTS: svfEventObj?.eventTimeSlot,
          frequency: svfEventObj?.frequency,
          isMultiTimeSlotSvf: svfEventObj?.isMultiTimeSlotSvf,
          eventOccurence: svfEventObj?.eventOccurence,
        }
      })
      svfList = getSvfsWithRequiredProps(svfList)
      if (_.isEqual(appType, constants.AppType.SITESTAFF)) {
        svfList = _.filter(svfList, (svf) => svf.formType == constants.FormTypes.RATER_ASSESSMENT)
      } else {
        svfList = _.filter(svfList, (svf) => svf.formType == constants.FormTypes.SUBJECT_DIARY)
      }
      return getSvfsBasedOnStudyType(svfList, scheduleDate, currentVisit.visitType)
    }
    return []
  }
)

export const getSvfsOfCurrentVisitWithMinEntries = createSelector(
  [getSvfsOfCurrentVisit],
  (svfListOfSelectedDate) => {
    let loSvfList = []
    let svfsOfEachEvent = _.groupBy(svfListOfSelectedDate, 'originatingEventId')
    _.forEach(svfsOfEachEvent, (event) => {
      if (event[0]?.isMultiTimeSlotSvf) {
        _.forEach(event, (f) => loSvfList.push(f))
      } else {
        const min = event[0]?.eventOccurence?.minumumOccurence || 1
        const max = Number(event[0]?.eventOccurence?.maximumOccurence) || 1
        const listOfEachForm = _.groupBy(event, 'form.formOid')
        _.forEach(listOfEachForm, (formList) => {
          const existingForms = _.filter(formList, (f) => f.isExistingForm);
          const noOfSets = _.size(formList - _.size(existingForms)) / max;
          const submittedFormsLength = _.filter(formList, (f) => f.status === 'COMPLETED').length
          const unsubmittedForms = _.filter(formList, (f) => f.status !== 'COMPLETED') //to push unsubmittedForms
          _.orderBy(unsubmittedForms, 'setNumber')
          if (noOfSets > 1) {
            const submittedMaxForms = submittedFormsLength - noOfSets * min
            for (let i = 0; i < noOfSets; i++) {
              if (submittedMaxForms < 0) {
                if (submittedFormsLength < (i + 1) * min && !_.isEmpty(unsubmittedForms)) {
                  if ((i + 1) * min - submittedFormsLength <= min) {
                    _.forEach(unsubmittedForms, (f, index) => {
                      if (index < (i + 1) * min - submittedFormsLength) {
                        loSvfList.push(f)
                      }
                    })
                  } else {
                    _.forEach(unsubmittedForms, (f, index) => {
                      if (index < min) {
                        loSvfList.push(f)
                      }
                    })
                  }
                }
              } else {
                if (submittedFormsLength <= (i + 1) * max && !_.isEmpty(unsubmittedForms)) {
                  loSvfList.push(unsubmittedForms[0])
                }
              }
            }
          } else if (
            submittedFormsLength >= min &&
            submittedFormsLength < max &&
            !_.isEmpty(unsubmittedForms)
          ) {
            loSvfList.push(unsubmittedForms[0])
          } else if (submittedFormsLength < min && !_.isEmpty(unsubmittedForms)) {
            _.forEach(unsubmittedForms, (f, index) => {
              if (index < min - submittedFormsLength) {
                loSvfList.push(f)
              }
            })
          }
        })
      }
    })
    return loSvfList
  }
)

const getSvfsBasedOnStudyType = (svfList, scheduleDate, visitType) => {
  return getSvfsBasedOnSelectedDate(svfList, scheduleDate, visitType)
}

const getSvfsBasedOnSelectedDate = (svfList, scheduleDate, visitType) => {
  const svfsOfSelectedDate = _.filter(svfList, (svf) => {
    if (
      moment(scheduleDate).isSameOrAfter(moment(svf.scheduleStartTime), 'day') &&
      moment(scheduleDate).isSameOrBefore(moment(svf.scheduleEndTime), 'day')
    ) {
      return svf
    } else {
      if (visitType !== 'UNSCHEDULED') {
        const shouldShowSvf = filterSvfByAllowDiaryDisplay(svf, scheduleDate)
        if (shouldShowSvf) {
          return svf
        }
      }
    }
  })
  return svfsOfSelectedDate
}

export const filterSvfByAllowDiaryDisplay = (svf, scheduleDate) => {
  if (svf.allowDiaryDisplay) {
    const endDate = moment(svf.scheduleEndTime).format('YYYY-MM-DD')
    const totalTimeForDisplay = _.isNull(svf.diaryDisplayDuration)
      ? moment(svf.scheduleEndTime).format('YYYY-MM-DD HH:mm')
      : moment(svf.scheduleEndTime)
          .add(svf.diaryDisplayDuration, 'minutes')
          .format('YYYY-MM-DD HH:mm')
    const currentTime = moment().format('HH:mm')
    const selectedDateTime = scheduleDate + ' ' + currentTime
    if (
      endDate &&
      moment(scheduleDate).isAfter(moment(endDate)) &&
      moment(selectedDateTime).isSameOrAfter(moment(svf.scheduleStartTime)) &&
      moment(selectedDateTime).isSameOrBefore(moment(totalTimeForDisplay))
    ) {
      return true
    } else if (
      endDate == null &&
      moment(scheduleDate).isSameOrAfter(moment(svf.scheduleStartTime), 'day')
    ) {
      return true
    } else {
      return false
    }
  }
  return false
}

export const getSvfEventTimeSlot = (currentVisit, svf, metadata) => {
  const svfCrfVersion = _.find(metadata.versions, (cv) => _.isEqual(cv.id, svf.crfVersion?.id))
  const visitCrfVersion = _.find(metadata.versions, (cv) =>
   _.isEqual(cv.id, currentVisit.crfVersion?.id)
  )
  let currentSchedule = {}
  if (_.isEqual(svf.scheduleId, currentVisit.currentScheduleId)) {
    currentSchedule = _.find(svfCrfVersion?.armVisitSchedules, (sc) =>
      _.isEqual(sc.id, currentVisit.currentScheduleId)
    )
  } else {
    const svfSchedule = _.find(svfCrfVersion?.armVisitSchedules, (sc) =>
      _.isEqual(sc.id, svf.scheduleId)
    )
    const visitSchedule = _.find(visitCrfVersion?.armVisitSchedules, (sc) =>
      _.isEqual(sc.id, currentVisit.currentScheduleId)
    )
    currentSchedule = _.isEqual(svfSchedule?.scheduleCode, visitSchedule?.scheduleCode)
      ? visitSchedule
      : svfSchedule
  }
  const currentEvent = _.find(currentSchedule?.formScheduleEvents, (event) =>
    _.isEqual(event.eventCode, svf.originatingEventId)
  )
  return !_.isEmpty(currentEvent) && !_.isEmpty(currentEvent?.eventTimeslot) ? currentEvent : {}
}

export const getSelectedSvf = createSelector(
  [getSvfsOfCurrentVisit, getSelectedSvfId],
  (svfList, selectedSvfId) => {
    const filteredFSvfs  = _.filter(svfList, (svf) => _.isEqual(svf.svfId, selectedSvfId))
    return _.isEmpty(filteredFSvfs) ? {} : filteredFSvfs[0]
  }
)

const getEventTimeSlot = (currentVisit, svf, metadata) => {
  let requiredEventSlot = {}
  if (currentVisit.visitType !== 'UNSCHEDULED') {
    const svfEvent = getSvfEventTimeSlot(currentVisit, svf, metadata)
    requiredEventSlot = {
      eventTimeSlot: { ...svfEvent?.eventTimeslot?.[0] },
      frequency: svfEvent?.eventFrequency?.frequency,
      isMultiTimeSlotSvf: svfEvent?.eventTimeslot?.length > 1 ? true : false,
      eventOccurence: svfEvent?.eventTimeslot?.length > 1 ? {} : svfEvent?.eventOccurrences,
    }
  } else {
    requiredEventSlot = {
      eventTimeSlot: {
        isAllDayEvent: true,
        allowDiaryDisplay: true,
        allowDiaryCompletion: 0,
        diaryDisplayDuration: 0,
      },
      frequency: 0,
      isMultiTimeSlotSvf: true,
      eventOccurence: {},
    }
  }
  return requiredEventSlot
}


export const getPreparedFormItems = createSelector([getState], (state) => {
  const mode = getModeFromSessionStorage()
  if(mode === "preview"){
    return prepareFormItemsForPreview(state)
  }else if(mode === 'printView'){
    return prepareFormItemsForPrint(state)
  }else{
    return prepareFormItemsForPatient(state)
  }
})

const prepareFormItemsForPatient = createSelector([getFieldGroupList, getOnMobileStatus, getUserCrfData, getSelectedSvf], (fieldGroupList, onMobile, formAnswers, selectedSvf) => {
  return prepareFormItems(fieldGroupList, selectedSvf, formAnswers, onMobile, null)
})

const prepareFormItemsForPrint = createSelector([getFormPrintForm, getFieldGroupListFormPrint, getCrfDataPool, getOnMobileStatus, getSelectedSvfId], (form, fieldGroupList, crfDataPool, onMobile, svfId) => {
  const selectedSvf = {form: form, status: 'COMPLETED', svfId: svfId}
  const formAnswers = crfDataPool?.[constants.AppType.PRINT]
  return prepareFormItems(fieldGroupList, selectedSvf, formAnswers, onMobile, "printView")
})


const prepareFormItemsForPreview = createSelector([getFormPreviewForm, getFormPreviewFieldGroupList, getCrfDataPool, getOnMobileStatus], (form, fieldGroupList, crfDataPool, onMobile) => {
  const selectedSvf = {form: form, id: form.id, status: 'COMPLETED'}
  const formAnswers = crfDataPool?.[constants.AppType.PREVIEW]
  return prepareFormItems(fieldGroupList, selectedSvf, formAnswers, onMobile, "preview")
})

const prepareFormItems = (fieldGroupList, selectedSvf, formAnswers, onMobile, mode) => {
  const isFormDisabled = disableForm(selectedSvf)
  if(!onMobile){
    return prepareFormItemsWeb(selectedSvf, fieldGroupList, mode, isFormDisabled, formAnswers)
  }else{
    return prepareFormItemsForMobile(selectedSvf, fieldGroupList, mode, isFormDisabled, formAnswers)
  }
}

const pushInstruction = (selectedSvf) => {
    if(selectedSvf?.form?.instruction ){
      return {
        key: `${selectedSvf?.form?.id}-instruction`,
        type: 'instruction',
        data: selectedSvf?.form?.instruction,
        formId: selectedSvf?.form?.id,
      }
    }
    return {}
}

const showSectionName = (uiSchema, element, mode) => {
  return  (uiSchema?.showSectionName && (!_.isEqual(_.toUpper(element?.fieldType), 'RESULT') || element.selfScored)) || mode === 'printView'
}

const showSectionInstruction = (element, mode) => {
  return  !(_.isEqual(_.toUpper(element?.fieldType), 'RESULT') || element?.selfScored || mode === 'printView') && element.instruction
}

const isGridLayout = (uiSchema) => {
  return uiSchema?.properties && (uiSchema?.properties?.commonOptions || uiSchema?.properties?.commonOptionsScore) &&
  uiSchema?.properties?.labelOrder &&
  uiSchema?.layout === 'grid'
}

const getFieldList = (fieldsByfieldGroup, element, selectedSvf, formAnswers) => {
  const fg = _.get(fieldsByfieldGroup, element?.id, [])
  if(!_.isEmpty(fg)){
    const loFormAnswers = _.get(formAnswers, selectedSvf?.svfId, {})
    return setDisabledFields(fg, loFormAnswers)
  }
  return []
}

const setUISchemaLabelOrder = (uiSchema) => {
  let loUiSchema = {...uiSchema}
  if (!_.isEmpty(loUiSchema?.properties)) {
    const hasKey = uiSchema?.properties?.hasOwnProperty('labelOrder');
    if (!hasKey) {
      loUiSchema.properties.labelOrder = ['optionLabel', 'optionScore'];
    }
  }
  return loUiSchema;
}

const getUiSchema = (element) => {
  if(!_.isEmpty(element?.uiSchema )){
    return JSON.parse(element?.uiSchema)
  }
  return defaultSchema
}

const pushSectionName = (uiSchema, element, mode) => {
  if (showSectionName(uiSchema, element, mode)) {
   return {
      isHidden: !uiSchema.showSectionName,
      key: `${element.id}-sectionName`,
      type: 'label',
      data: element.fieldGroupName,
      fieldGroupOid: element.fieldGroupOid,
      layout: uiSchema.layout,
    }
  }
  return {}
}

const pushSectionInstruction = (element, mode) => {
  if (showSectionInstruction(element, mode)) {
    return {
      key: `${element.id}-section-instruction`,
      type: 'instruction',
      data: element.instruction,
      fieldGroupOid: element.fieldGroupOid,
    } 
  }
  return {}
}

const pushGridConfig = (updatedFieldList, element, uiSchema) => {
  if (isGridLayout(uiSchema)) {
    const fields = _.filter(updatedFieldList, (f) => f.fieldType != 'result')
    if (!_.isEmpty(fields)) {
      return {
        key: `${element.id}-header`,
        type: 'header',
        data: fields[0].dictionary.options,
        fieldGroupOid: element.fieldGroupOid,
        scoringEnabled: element.scoringEnabled,
        uiSchema,
      }
    }
  }
  return {}
}

const pushFieldConfig = (updatedFieldList, isFormDisabled, uiSchema, flatListData, mode) => {
  let loFieldListData = [...flatListData]
  let resultField = null
  _.forEach(updatedFieldList, (field) => {
    const fields = {
      key: field.id,
      type: 'field',
      data: {
        ...field,
        disabled: field.disabled || isFormDisabled,
        fieldGroup: {
          ...field.fieldGroup,
          uiSchema,
        },
      },
    }
    if (field.fieldType !== 'result') {
      loFieldListData.push(fields)
    } else if (
      field.fieldType === 'result' &&
      (field.fieldGroup.selfScored || mode === 'printView')
    ) {
      resultField = fields
    }
  })
  if(resultField){
    loFieldListData.push(resultField)
  }
  return loFieldListData
}

const pushDivider = (element) => {
   return {
    key: `${element.id}-divider`,
    type: 'divider',
    fieldGroupOid: element.fieldGroupOid,
  }
}
export const prepareFormItemsWeb = (selectedSvf, fgsWithFields, mode, isFormDisabled, formAnswers) => {
  let fieldGroups = _.orderBy(fgsWithFields, 'ordinal')
  let fieldList = _.flatten(_.map(fgsWithFields, (fg) => fg.fields))
  const fieldsByfieldGroup = _.groupBy(fieldList, 'fieldGroup.id')

  let flatListData = []
  flatListData.push(pushInstruction(selectedSvf))
  for (const fg of fieldGroups) {
    const element = fg
    let uiSchema = getUiSchema(element)
    uiSchema = setUISchemaLabelOrder(uiSchema)
    const updatedFieldList = getFieldList(fieldsByfieldGroup, element, selectedSvf, formAnswers)
    flatListData.push(pushSectionName(uiSchema, element, mode)) 
    flatListData.push(pushSectionInstruction(element, mode))
    flatListData.push(pushGridConfig(updatedFieldList, element, uiSchema))
    flatListData = pushFieldConfig(updatedFieldList, isFormDisabled, uiSchema, flatListData, mode)
    flatListData.push(pushDivider(element))
  }
  flatListData = _.filter(flatListData, ele => !_.isEmpty(ele))
  if(selectedSvf?.form?.licenseHolder){
    flatListData.push({
      key: `${selectedSvf?.form?.id}-footer`,
      type: 'footer',
      data: selectedSvf?.form?.licenseHolder.split('\r\n').join('<br/>'),
      formId: selectedSvf?.form?.id,
      isHidden: true,
    })
  }
  return flatListData
}

const addResultField = (field, mode) => {
  return field?.fieldType === 'result' && (field?.fieldGroup?.selfScored || mode === 'printView')
}

const shouldFieldBeAdded = (field, uiSchema, isOnSamePage) => {
  return (!field.disabled || (uiSchema?.skipType === 'hide' && field.disabled && isOnSamePage) || uiSchema?.skipType !== 'hide')
}


const isFieldOnSamePage = (fields, linkedFieldId) => {
  if(!_.isEmpty(linkedFieldId)){
    const linkedField = _.find(fields, (field) => {
      return field.data.id === linkedFieldId
    })
    return !_.isEmpty(linkedField)
  }else{
    return true
  }
}

export const prepareFormItemsForMobile = (
  selectedSvf,
  fgsWithFields,
  mode,
  isFormDisabled,
  formAnswers
) => {
  let fieldGroups = _.orderBy(fgsWithFields, 'ordinal')
  let fieldList = _.flatten(_.map(fgsWithFields, (fg) => fg.fields))
  const fieldsByfieldGroup = _.groupBy(fieldList, 'fieldGroup.id')
  const headers = []
  let footer = []
  let sections = []
  let pageNo = 1
  for (const fg of fieldGroups) {
    let flatListData = []
    let fieldIndex = 0
    let sectionHeaders = []
    const element = fg
    let uiSchema = getUiSchema(element)
    uiSchema.mobilePageSize = uiSchema?.mobilePageSize || 5
    uiSchema.skipType = uiSchema?.skipType || 'hide'
    uiSchema = setUISchemaLabelOrder(uiSchema)
    const updatedFieldList = getFieldList(fieldsByfieldGroup, element, selectedSvf, formAnswers)

    if (!_.isEmpty(headers)) {
      _.forEach(headers, (h) => sectionHeaders.push(h))
    }
    sectionHeaders.push(pushSectionName(uiSchema, element, mode))
    sectionHeaders.push(pushSectionInstruction(element, mode))
    flatListData = pushGridConfig(updatedFieldList, element, uiSchema)

    flatListData = _.filter(flatListData, ele => !_.isEmpty(ele))
    sectionHeaders = _.filter(sectionHeaders, ele => !_.isEmpty(ele))

    let resultField
    _.forEach(updatedFieldList, (field, index) => {
      const fields = {
        key: field.id,
        type: 'field',
        data: {
          ...field,
          disabled: field.disabled || isFormDisabled,
          fieldGroup: {
            ...field.fieldGroup,
            uiSchema,
          },
        },
      }

      if (field.fieldType !== 'result') {
        if (fieldIndex >= uiSchema?.mobilePageSize) {
          // Changes made to display only clickable image in page for mobile, if field type is clickable image
          sections.push({
            headers: sectionHeaders,
            data: [...flatListData],
          })
          pageNo = pageNo + 1

          flatListData = []
          fieldIndex = 0
        }
        const isOnSamePage = isFieldOnSamePage(flatListData, field.linkedFieldId) 
        // add field if field is not disabled
        //or if field is disabled but skip type is disabled
        // or if field is disabled and current page is greater than or equal to pageNo
        if (shouldFieldBeAdded(field, uiSchema, isOnSamePage)) {
          flatListData.push(fields)
          fieldIndex = fieldIndex + 1
        }
      } else if (addResultField(field, mode)) {
        resultField = fields
      }
    })
    if (!_.isEmpty(flatListData)) {
      sections.push({
        headers: sectionHeaders,
        data: [...flatListData],
      })
      pageNo = pageNo + 1
      flatListData = []
    }
    resultField && flatListData.push(resultField)
    resultField = null
  }

  if(selectedSvf?.form?.licenseHolder){
    footer.push({
      key: `${selectedSvf?.form?.id}-footer`,
      type: 'footer',
      data: selectedSvf?.form?.licenseHolder.split('\r\n').join('<br/>'),
      formId: selectedSvf?.form?.id,
      isHidden: true,
    })
  }  
  return {
    headers,
    sections,
    footer,
  }
}


const isFieldDisabled = (field, currentField, destFieldOrdinal) => {
  return field.ordinal < currentField.ordinal
    ? field.disabled
    : !!(field.ordinal > currentField.ordinal && field.ordinal < destFieldOrdinal)
}

const setDisabledFields = (fieldList, currentFormAnswers) => {
  let loFieldList = [...fieldList]
  fieldList.forEach((currentField) => {
    if (!_.isEmpty(currentField.fieldRules)) {
      const field = {
        ...currentField,
        crfData: { ...currentFormAnswers?.[currentField.fieldOid] },
      }
      const existedDisabledField = _.filter(
        loFieldList,
        (fd) => fd.id == currentField.id && fd.disabled
      )
      if (field.crfData && _.isEmpty(existedDisabledField)) {
        const destFieldOrdinal = fetchNextFieldByFieldRule(field, fieldList)
        if (destFieldOrdinal) {
          loFieldList = _.map(loFieldList, (field) => ({
            ...field,
            disabled: isFieldDisabled(field, currentField, destFieldOrdinal),
            linkedFieldId: isFieldDisabled(field, currentField, destFieldOrdinal)
              ? currentField.id
              : null,
          }))
        }
      }
    }
  })
  return loFieldList
}

export const prepareFormInstruction = (selectedSvf) => {
  // get the formData
  // get the fields [{id,type,label,value,options}]
  // if the form has step layout then push only the first field into the flat list data
  const formInstruction = []
  if (selectedSvf.form.instruction) {
    const instruction = {
      key: selectedSvf.formId,
      type: 'instruction',
      data: selectedSvf.form.instruction,
    }
    formInstruction.push(instruction)
  }
  return formInstruction
}