import React from 'react';
import {StyleSheet, Text, View, Dimensions, Platform, TouchableOpacity } from 'react-native';
import Modal from '../../common/Modal/Modal';
import CustomImage from '../../common/CustomImage/CustomImage';
import diaryUpdateIcon from '../../../../assets/base64/diaryUpdateIcon';
import { Colors } from '../../common/ColorScheme';
import { useDispatch } from 'react-redux';
import { updateStudyMetaData } from '../../../actions/studyMetaData';
import PropTypes from 'prop-types';
import constants from '../../../constants/constants';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

const QuestionnaireUpdate = (props) => {
    const { screenProps: { t }, appType } = props;
    const dispatch = useDispatch();
    const upgradeQuestionnaires = async () => {
       await dispatch(updateStudyMetaData(true));
    }
    return (
        <View>
      <Modal
      headerText={''}
      hideModal={() => {}}
      t={t}
      style = {styles.modalContainerStyle}
    >
        <View style = {styles.container}>
            <View style = {{ flex: 0.8}}>
            <View style = {Platform.OS === 'web' ? styles.webImageContainer : styles.mobileImageContainer} >
                <CustomImage
                    uri = {`data:image/png;base64,${diaryUpdateIcon}`}
                    resizeMode="contain"
                    style={[styles.image]}
                    testID={'questionnaire-update-image-os-web-image'}
                    accessible={true}
                />
            </View>
              <View>
                    <Text style = {styles.headerSubtext}>{t('UpdateNews')}</Text>
                    <Text style = {styles.headerPrimaryText}>{t(constants.AppType.SITESTAFF === appType ? 'ClincianQuestionnaireUpdateTitle' : 'QuestionnaireUpdateTitle')}</Text>
                    <Text style = {styles.infoText}>{t(constants.AppType.SITESTAFF === appType ? 'ClincianQuestionnaireUpdateMessage' : 'ContactSite')}</Text>
              </View>
              </View>
              <View style = {{ flex: 0.2}}>
                <TouchableOpacity style = {styles.footerButtonStyle} onPress={upgradeQuestionnaires}>
                    <Text style = {styles.buttonTextStyle}>{t('OK')}</Text>
                </TouchableOpacity>
              </View>
        </View>
        </Modal>
 </View>
    )
}

const styles  = StyleSheet.create({
    container: {
        backgroundColor: Colors.white,
        alignSelf: 'center',
        marginTop: SCREEN_WIDTH >= 1030 ? 100 : 0,
        padding: 20,
        height: SCREEN_HEIGHT*0.6,
        borderRadius: 10,
        elevation: 10, // Elevation depth for Android
        // Shadow properties for iOS
        shadowColor: '#000',
        shadowOpacity: 0.3,
        shadowRadius: 5,
    },
    image: {
        flex: 1,
        width: "80%"
    },
    headerSubtext: {
        fontWeight: "600",
        fontSize: 12,
        lineHeight: 24,
        textAlign: "center",
        fontFamily: 'Inter',
        textTransform: 'uppercase',
        color: Colors.primaryTextColor
    },
    headerPrimaryText: {
        fontWeight: "400",
        fontSize: 25,
        lineHeight: 30,
        textAlign: "center",
        fontFamily: 'Inter',
        color: Colors.primaryTextColor,
        marginTop: 10,
        marginBottom: 10
    },
    infoText: {
        fontWeight: "400",
        fontSize: 15,
        lineHeight: 18,
        textAlign: "center",
        fontFamily: 'Inter',
        color: Colors.primaryTextColor
    },
    footerButtonStyle: {
        backgroundColor: Colors.primaryColor,
        borderRadius: 5,
        padding: 10,
        alignItems: 'center',
        marginTop: 20
    },
    buttonTextStyle: {
        color: Colors.white,
        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: "500",
        lineHeight: 26
    },
    modalContainerStyle: {
        flex: 1,
        borderRadius: 5,
        width: SCREEN_WIDTH >= 1030 ? 800*0.7 : SCREEN_WIDTH*0.75,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "transparent",
    },
    mobileImageContainer: {
        flex: 0.7, 
        alignItems: "center",
        justifyContent: "center"
    },
    webImageContainer: {
        flex: 0.7, 
        justifyContent: "center"
    }
})
QuestionnaireUpdate.propTypes = {
    screenProps: PropTypes.object.isRequired,
    appType: PropTypes.string.isRequired
}
export default QuestionnaireUpdate;