import * as Notifications from 'expo-notifications'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateDeviceToken } from '../../actions/storeAppStatus';
import { updateSubjectDeviceToken } from '../../actions/login';
import { Platform } from 'react-native';
import Constants from 'expo-constants'
import * as TaskManager from 'expo-task-manager'
import { captureSentryError } from '../../utils/logger.native';

const BACKGROUND_VERSION_UPGRADE_NOTIFICATION_TASK = 'BACKGROUND_VERSION_UPGRADE_NOTIFICATION_TASK'

const NotificationSetup = (props) => {
    const { children, user, subject, updateQuestionnaireVersionRequired } = props;
    const dispatch = useDispatch();
    let notificationListener = null;
    let responseListener = null;

    const defineBackgroundTask = () => {
        TaskManager.defineTask(BACKGROUND_VERSION_UPGRADE_NOTIFICATION_TASK, ({ data }) => {
            if (data) {
                updateQuestionnaireVersionRequired();
          }})
    }
    useEffect(() => {
        defineBackgroundTask();
    })
    useEffect(() => {
        if (subject?.id) {
            updateSubjectToken();
        }
        return () => {
            if (notificationListener) {
                Notifications.removeNotificationSubscription(notificationListener);
            }
            if (responseListener) {
                Notifications.removeNotificationSubscription(responseListener);
            }
        }
    }, [subject?.id])

    const updateSubjectToken = async () => {

        const token = await setupPushNotification();
        notificationListener = Notifications.addNotificationReceivedListener(notification => {
            console.log('Notification received!', notification);
            if (notification.request.content.title?.includes('Upgrade')) {
                updateQuestionnaireVersionRequired();
            }
        });
        console.log('Notification listener: ', notificationListener);
        responseListener = Notifications.addNotificationResponseReceivedListener(response => {
            console.log('Notification response received!', response);
            
            if (response.notification.request.content.title?.includes('Upgrade')) {
                updateQuestionnaireVersionRequired();
            }
        });
        await dispatch(updateSubjectDeviceToken({
            ...subject,
            deviceToken: token
        }, user?.id));
    }
    const setupPushNotification = async () => {
        const token = await registerForPushNotificationsAsync();
        if (token) {
            dispatch(updateDeviceToken(token));
            return token
        }
    }
    const registerForPushNotificationsAsync = async () => {
        if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            });
        }
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus === 'granted') {
            const projectId =
                Constants?.expoConfig?.extra?.eas?.projectId ?? Constants?.easConfig?.projectId;
            try {
                const pushTokenString = (
                    await Notifications.getExpoPushTokenAsync({
                        projectId,
                    })
                ).data;
                console.log(pushTokenString);
                return pushTokenString;
            } catch (e) {
                return null;
            }
        }
    }

    return children;
}

export default NotificationSetup
