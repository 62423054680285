const locale_codes = {
    "en-us": {
        "code": "en",
        "time12": true
    },
    "cs": {
        "code": "cs",
        "time12": false
    },
    "de": {
        "code": "de",
        "time12": false
    },
    "el": {
        "code": "el",
        "time12": true
    },
    "fr": {
        "code": "fr",
        "time12": false
    },
    "it": {
        "code": "it",
        "time12": false
    },
    "ja": {
        "code": "ja",
        "time12": false
    },
    "ko": {
        "code": "ko",
        "time12": true
    },
    "pl": {
        "code": "pl",
        "time12": false
    },
    "pt-br": {
        "code": "ptBR",
        "time12": false
    },
    "ru": {
        "code": "ru",
        "time12": false
    },
    "sv": {
        "code": "sv",
        "time12": false
    },
    "tr": {
        "code": "tr",
        "time12": false
    },
    "zh-cn": {
        "code": "zhCN",
        "time12": false
    },
    "nl": {
        "code": "nl",
        "time12": false
    },
    "fr-ca": {
        "code": "frCA",
        "time12": false
    },
    "fi": {
        "code": "fi",
        "time12": false
    },
    "es": {
        "code": "es",
        "time12": false
    },
    "da": {
        "code": "da",
        "time12": false
    },
    "hi": {
        "code": "hi",
        "time12": true
    },
    "pt": {
        "code": "pt",
        "time12": false
    },
    "nb": {
        "code": "nb",
        "time12": false
    },
    "es-mx": {
        "code": "esMX",
        "time12": true
    },
}

export default locale_codes;
